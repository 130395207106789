import React, { Component  } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, InputNumber, Row, Col, Typography, Select, Button, DatePicker, Table, Collapse, Radio, Space, Modal, Checkbox, Spin, Tooltip } from 'antd';
import { SearchOutlined, DownloadOutlined, CheckOutlined, FunnelPlotOutlined, ArrowLeftOutlined, ArrowRightOutlined, TableOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import SoldQuoteLog from './SoldQuoteLog';
import ActivitySummary from './ActivitySummary';
import QuoteLogEntry from './QuoteLogEntry';
import { IGraphCommandContribution } from '@antv/xflow-core';
import PremiumAverage from './PremiumAverage';
import Confetti from 'react-confetti';
import ConfettiExplosion from 'react-confetti-explosion';
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;


class QuoteLog extends Component {
    constructor(props) {
      super(props);
        this.state = {
            id: 0,
            date: moment().format("MM/DD/YYYY"),
            agent: '',
            status: '',
            firstName: '',
            lastName: '',
            leadSource: '',
            dwelling: [],
            auto: 0,
            specialty: [],
            objection: 'N/A',
            ineligible: 'N/A',
            notes: '',
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"), //moment().subtract(7, 'days').format("MM/DD/YYYY"),
            dateTo: moment().endOf('month').format("MM/DD/YYYY"), //moment().format("MM/DD/YYYY"),
            tablePageSize: 50,
            searchFilter: 'date',
            openSearch: true,
            zip: '',
            invalid: false,
            visible: false,
            selectedDwelling: [],
            selectedSpecialty: [],
            selectedDwellingCnt: [],
            selectedSpecialtyCnt: [],
            dwellingCheckboxes: [],
            dwellingSoldCheckboxes: [],
            specialtyCheckboxes: [],
            specialtySoldCheckboxes: [],
            soldEffectiveDate: moment().format("MM/DD/YYYY"),
            soldBindDate: moment().format("MM/DD/YYYY"),
            soldAuto: 0,
            autoPremium: '',
            quoteLogSoldId: 0,
            isSold: false,
            selectedQuoteLog: [],
            reportFilter: 'All',
            selectedColumns: [],
            modalVisible: false,
            columns: [],
            pageNumber: 1,
            rows: 50,
            activitySummaryRefresh: false,
            loggedInAgentName: '',
            tableSearch: [],
            filterdInfo: [],
            showConfetti: false,
            recycleConfetti: true,
            showFullNote: false
        }
        this.handleListClick = this.handleListClick.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleLeadSourceChange = this.handleLeadSourceChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleObjectionChange = this.handleObjectionChange.bind(this);
        this.handleIneligibleChange = this.handleIneligibleChange.bind(this);
        this.handleDwellingChange = this.handleDwellingChange.bind(this);
        this.handleSpecialtyChange = this.handleSpecialtyChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        //this.handleNew = this.handleNew.bind(this);
        this.handleTableClick = this.handleTableClick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleOpenSearch = this.handleOpenSearch.bind(this);
        this.handleDateRadioChange = this.handleDateRadioChange.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleDwellingNumberChange = this.handleDwellingNumberChange.bind(this);
        this.handleSpecialtyNumberChange = this.handleSpecialtyNumberChange.bind(this);
        this.handleDwellingSoldChange = this.handleDwellingSoldChange.bind(this);
        this.handleSpecialtySoldChange = this.handleSpecialtySoldChange.bind(this);
        this.handleSoldChange = this.handleSoldChange.bind(this);
        this.handleEntryVisibleChange = this.handleEntryVisibleChange.bind(this);
        this.exportToCSV = this.exportToCSV.bind(this);
        this.handlePanelChange = this.handlePanelChange.bind(this);
        this.handleReportRadioChange = this.handleReportRadioChange.bind(this);
        this.handleColumnChange = this.handleColumnChange.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }
    
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        if(agencyId == null){
            this.props.history.push('/');
        }
        this.props.getAgent(agencyId);
        this.props.getReferringAgent(agencyId);
        this.props.getLeadSource(agencyId);
        this.props.getSpecialty();
        this.props.getObjection();
        this.props.getStatus();
        this.props.getDwelling();
        this.props.getIneligible();
        this.props.getQuoteLogReport(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), this.state.reportFilter);
        
        const selectedColumns = localStorage.getItem('selectedColumns');
        this.setState({selectedColumns: selectedColumns == null ? [] : selectedColumns});
        //this.props.getArchivedQuoteLog(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), this.state.reportFilter, this.state.pageNumber, this.state.rows);
        //this.setState({dateFrom: moment().startOf('month').format("MM/DD/YYYY"), dateTo: moment().endOf('month').format("MM/DD/YYYY")});
        //this.setState({dateFrom: moment().subtract(7, 'days').format("MM/DD/YYYY"), dateTo: moment().format("MM/DD/YYYY")});
        //this.props.getQuoteLog(agencyId);
        // const container = document.querySelector('.container')
        // const fireworks = new Fireworks(container, { /* options */ })
        // fireworks.start()
    }
    componentDidUpdate(prevProps){
        if(this.props.quoteLog != prevProps.quoteLog){
             const agencyId = localStorage.getItem('agencyId');
            
             //this.props.getArchivedQuoteLog(agencyId, this.state.dateFrom, this.state.dateTo);
             //this.props.getQuoteLogSummary(agencyId, this.state.dateFrom, this.state.dateTo);
             //this.props.getQuoteLogVendorSummary(agencyId, this.state.dateFrom, this.state.dateTo);
        }
        if(this.props.dwelling != prevProps.dwelling){
            this.setState({dwellingCheckboxes: this.props.dwelling})
        }
        if(this.props.specialty != prevProps.specialty){
            this.setState({specialtyCheckboxes: this.props.specialty})
            
        }
        if(this.props.quoteLogSold != prevProps.quoteLogSold){
            this.setState(
                {
                    specialtyCheckboxes: this.props.quoteLogSold.specialtySoldCheckboxes,
                    dwellingCheckboxes: this.props.quoteLogSold.dwellingSoldCheckboxes,
                    specialtySoldCheckboxes: this.props.quoteLogSold.specialtySoldCheckboxes,
                    dwellingSoldCheckboxes: this.props.quoteLogSold.dwellingSoldCheckboxes,
                    effectiveDate: this.props.quoteLogSold.effectiveDate,
                    bindDate: this.props.quoteLogSold.bindDate,
                    soldAuto: this.props.quoteLogSold.auto,
                    autoPremium: this.props.quoteLogSold.autoPremium,
                    quoteLogSoldId: this.props.quoteLogSold.id,
                    isSold: true
                });
        }
    }
    handleListClick = (vendor) => {
        this.setState({
            
        });
    }
    handleStateChange = (e) => {
        this.setState({state: e});
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleVendorTypeChange = (e) => {
        this.setState({vendorType: e});
    }
    handleNumberChange = (e, name) => {
        this.setState({
            [name]: e
        })
    }
    handleDateChange(date, dateString) {
        this.setState({
            date: date
        })
    }
    handleDateRangeChange(dates, dateStrings, info) {
        console.log(info);
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        if(dateFrom != ''){
            this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, this.state.reportFilter);
            this.setState({
                dateFrom: dateFrom,
                dateTo: dateTo 
            });
        }
        console.log(dateStrings);
    }
    handleAgentChange = (e) => {
        this.setState({agent: e});
    }
    handleLeadSourceChange = (e) => {
        this.setState({leadSource: e});
    }
    handleStatusChange = (e) => {
        const agencyId = localStorage.getItem('agencyId');
        this.setState({status: e});
        if(e == 'SOLD' ) {//&& (agencyId == '1168673106' || agencyId == '1246366855' || agencyId == '1677401557')){
            this.setState({isSold: true});
        } else{
            this.setState({isSold: false});
        }
    }
    handleObjectionChange = (e) => {
        this.setState({objection: e});
    }
    handleIneligibleChange = (e) => {
        this.setState({ineligible: e});
    }
    handleSpecialtyChange = (e) => {
        const data = this.state.specialtyCheckboxes;
        const { value, checked } = e.target;
        const updatedData = data.map(group => {
        if (group.id === value) {
            return {
            ...group,
            checked: group.checked === checked ? null : checked,
            cnt: 1
            };
        } else {
            return group;
        }
        });

        this.setState({ specialtyCheckboxes: updatedData }, () => console.log(this.state.specialtyCheckboxes));
    }
    handleSpecialtyNumberChange = (e, id) => {
        const data = this.state.specialtyCheckboxes;
        const updatedData = data.map(group => {
        if (group.id === id) {
            return {
            ...group,
            cnt: e
            };
        } else {
            return group;
        }
        });

        this.setState({ specialtyCheckboxes: updatedData }, () => console.log(this.state.specialtyCheckboxes));
    }
    handleDwellingChange = (e) => {
        const data = this.state.dwellingCheckboxes;
        const { value, checked } = e.target;
        const updatedData = data.map(group => {
        if (group.id === value) {
            return {
            ...group,
            checked: group.checked === checked ? null : checked,
            cnt: 1
            };
        } else {
            return group;
        }
        });

        this.setState({ dwellingCheckboxes: updatedData }, () => console.log(this.state.dwellingCheckboxes));
    }
    handleDwellingSoldChange = (e) => {
        this.setState({ dwellingSoldCheckboxes: e }, () => console.log(this.state.dwellingSoldCheckboxes));
    }
    handleSpecialtySoldChange = (e) => {
        this.setState({ specialtySoldCheckboxes: e }, () => console.log(this.state.specialtySoldCheckboxes));
    }
    handleDwellingNumberChange = (e, id) => {
        const data = this.state.dwellingCheckboxes;
        const updatedData = data.map(group => {
        if (group.id === id) {
            return {
            ...group,
            cnt: e
            };
        } else {
            return group;
        }
        });

        this.setState({ dwellingCheckboxes: updatedData }, () => console.log(this.state.dwellingCheckboxes));
    }
    clearDwellingCheckboxes = () => {
        const data = this.state.dwellingCheckboxes;
        const updatedData = data.map(group => {
        return {
            ...group,
            checked: false,
            cnt: 0
            };
        });
        this.setState({ dwellingCheckboxes: updatedData }, () => console.log(this.state.dwellingCheckboxes));
    }
    clearSpecialtyCheckboxes = () => {
        const data = this.state.specialtyCheckboxes;
        const updatedData = data.map(group => {
        return {
            ...group,
            checked: false,
            cnt: 0
            };
        });
        this.setState({ specialtyCheckboxes: updatedData }, () => console.log(this.state.specialtyCheckboxes));
    }
    clearDwellingSoldCheckboxes = () => {
        const data = this.state.dwellingSoldCheckboxes;
        const updatedData = data.map(group => {
        return {
            ...group,
            checked: false,
            cnt: 0
            };
        });
        this.setState({ dwellingSoldCheckboxes: updatedData }, () => console.log(this.state.dwellingSoldCheckboxes));
    }
    clearSpecialtySoldCheckboxes = () => {
        const data = this.state.specialtySoldCheckboxes;
        const updatedData = data.map(group => {
        return {
            ...group,
            checked: false,
            cnt: 0
            };
        });
        this.setState({ specialtySoldCheckboxes: updatedData }, () => console.log(this.state.specialtySoldCheckboxes));
    }
    handleTableClick = (quoteLog) => {
        const agencyId = localStorage.getItem('agencyId');
        this.setState({
            selectedQuoteLog: quoteLog,
            visible: true,
            soldAuto: quoteLog.auto
            // id: quoteLog.id,
            // date: moment(quoteLog.dts).format("MM/DD/YYYY"),
            // agent: quoteLog.agent,
            // status: quoteLog.status,
            // firstName: quoteLog.firstName,
            // lastName: quoteLog.lastName,
            // leadSource: quoteLog.leadSource,
            // auto: quoteLog.auto,
            // objection: quoteLog.objection,
            // ineligible: quoteLog.ineligible,
            // notes: quoteLog.notes,
            // zip: quoteLog.zip,
            // visible: true
        });
        // this.props.getQuoteLogDwellings(quoteLog.id);
        // this.props.getQuoteLogSpecialty(quoteLog.id);
        // if(quoteLog.status == 'SOLD') {// && (agencyId == '1168673106' || agencyId == '1246366855' || agencyId == '1677401557')) {
        //     this.props.getQuoteLogSold(quoteLog.id);
        // }else{
        //     this.setState(
        //         {
        //             specialtyCheckboxes: [],
        //             dwellingCheckboxes: [],
        //             effectiveDate: moment().format("MM/DD/YYYY"),
        //             bindDate: moment().format("MM/DD/YYYY"),
        //             soldAuto: 0,
        //             autoPremium: '',
        //             quoteLogSoldId: 0,
        //             isSold: false
        //         });
        // }
    }
    handleSave(event) {
        event.preventDefault();
        const id = this.state.id;
        const date = this.state.date;
        const agent = this.state.agent;
        const status = this.state.status;
        const firstName = this.state.firstName;
        const lastName = this.state.lastName;
        const leadSource = this.state.leadSource;
        const dwelling = this.state.dwellingCheckboxes;
        const auto = this.state.auto;
        const specialty = this.state.specialtyCheckboxes;
        const objection = this.state.objection;
        const ineligible = this.state.ineligible;
        const notes = this.state.notes;
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const zip = this.state.zip;
  
        if(date == '' | agent == '' | status == '' | firstName == '' | lastName == '' | leadSource == '' | objection == '' | ineligible == '' | zip == ''){
            this.setState({invalid: true});
        }
        else{
            this.props.createQuoteLog(id, date, agent, status, firstName, lastName, leadSource, dwelling, auto, specialty, objection, ineligible, notes, agencyId, dateFrom, dateTo, zip);

            if(this.state.isSold && id > 0){
                const quoteLogId = this.state.id;
                const quoteLogSoldId = this.state.quoteLogSoldId;
                const effectiveDate = this.state.soldEffectiveDate;
                const bindDate = this.state.soldBindDate;
                const soldAuto = this.state.soldAuto;
                const autoPremium = this.state.autoPremium;
                const soldDwelling = this.state.dwellingSoldCheckboxes;
                const soldSpecialty = this.state.specialtySoldCheckboxes;
                this.props.createSoldQuoteLog(quoteLogSoldId, quoteLogId, bindDate, effectiveDate, soldAuto, autoPremium, soldDwelling, soldSpecialty)
            }

            this.setState({
                id: 0,
                date: moment().format("MM/DD/YYYY"),
                agent: '',
                status: '',
                firstName: '',
                lastName: '',
                leadSource: '',
                auto: 0,
                objection: 'N/A',
                ineligible: 'N/A',
                notes: '',
                zip: '',
                invalid: false,
                visible: false
            });
            this.clearDwellingCheckboxes();
            this.clearSpecialtyCheckboxes();
        }
    }
    handleDelete(event) {
        event.preventDefault();
        const id = this.state.id;
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
 
        this.props.deleteQuoteLog(id, dateFrom, dateTo);

        this.setState({
            id: 0,
            date: moment().format("MM/DD/YYYY"),
            agent: '',
            status: '',
            firstName: '',
            lastName: '',
            leadSource: '',
            auto: 0,
            objection: 'N/A',
            ineligible: 'N/A',
            notes: '',
            zip: ''
        })
        this.clearDwellingCheckboxes();
        this.clearSpecialtyCheckboxes();
    }
    handleSearch = (searchText) => {
        const agencyId = localStorage.getItem('agencyId');
        const searchFilter = this.state.searchFilter;
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        this.props.searchQuoteLog(agencyId, searchFilter, searchText, dateFrom, dateTo);
    };
    handleTableSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        if(selectedKeys[0] == undefined) {
            selectedKeys[0] = '';
        }
        console.log("Search: " + selectedKeys[0]);
        const tableSearch = this.state.tableSearch;
        let searchData = { searchIndex: dataIndex, searchText: selectedKeys[0] };
        const i = tableSearch.findIndex(e => e.searchIndex === dataIndex);
        if (i > -1) {
            tableSearch[i] = searchData;
        } else {
            tableSearch.push(searchData);
        }
        console.log(tableSearch);
        this.setState({
            tableSearch: tableSearch,
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
      };
    handleReset = clearFilters => {
        clearFilters();
        //this.setState({ searchText: '' });
    };
    handleOpenModal = () => {
        this.setState({ visible: true });
    }
    handleCloseModal = () => {
        this.setState({
            modalVisible: false,
        });
        localStorage.setItem('selectedColumns', this.state.selectedColumns);
        console.log(this.state.selectedColumns);
    }
    handleEntryVisibleChange = (status) => {
        if(status == "SOLD"){
            this.setState({showConfetti: true});
            setTimeout(() => {
                this.setState({ text: "show confetti", recycleConfetti: false });
            }, 5000);
        }
        this.setState({ visible: !this.state.visible });
        const seed = Math.random();
        console.log("Seed: " + seed);
        if(this.state.visible == false){
            this.setState({selectedQuoteLog: [], seed: seed, showConfetti: false, recycleConfetti: true});
        }
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#97E45D' : undefined }} />,
        onFilter: (value, record) =>
        record != null &&
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
    });
    handleRadioChange(e) {
        this.setState({
            searchFilter: e.target.value
        });
        if(e.target.value == 'all'){
            const dateFrom = '';
            const dateTo = '';
            const agencyId = localStorage.getItem('agencyId');
            
            this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, this.state.reportFilter);
            // this.props.getQuoteLogSummary(agencyId, dateFrom, dateTo);
            // this.props.getQuoteLogVendorSummary(agencyId, dateFrom, dateTo);
            this.setState({
                dateFrom: dateFrom,
                dateTo: dateTo 
            });
        }
    }
    handleDateRadioChange(e) {
        this.setState({
            dateFilter: e.target.value
        });
        var dateFrom = '';
        var dateTo = ''; 
        const agencyId = localStorage.getItem('agencyId');
        if(e.target.value == 'today'){
            dateFrom = moment().format('MM/DD/YYYY');
            dateTo = moment().format('MM/DD/YYYY'); 
        }
        if(e.target.value == 'thisWeek'){
            dateFrom = moment().startOf('week').format('MM/DD/YYYY');
            dateTo = moment().endOf('week').format('MM/DD/YYYY'); 
        }
        else if(e.target.value == 'lastWeek'){
            dateFrom = moment().subtract(1, 'week').startOf('week').format('MM/DD/YYYY');
            dateTo = moment().subtract(1, 'week').endOf('week').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'thisMonth'){
            dateFrom = moment().startOf('month').format('MM/DD/YYYY');
            dateTo = moment().endOf('month').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'lastMonth'){
            dateFrom = moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY');
            dateTo = moment().subtract(1, 'month').endOf('month').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'all'){
            dateFrom = '';
            dateTo = '';
        }
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, this.state.reportFilter);
        // this.props.getQuoteLogSummary(agencyId, dateFrom, dateTo);
        // this.props.getQuoteLogVendorSummary(agencyId, dateFrom, dateTo);
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo 
        });
    }
    handleOpenSearch() {
        const openSearch = this.state.openSearch;
        this.setState({
            openSearch: !openSearch
        });
    }
    handleSoldChange(name, e) {
        this.setState({[name]: e});
        //this.setState({soldAuto: e.auto, soldAutoPremium: e.premiumAuto, soldBindDate: e.bindDate, soldEffectiveDate: e.effectiveDate})
    }
    exportToCSV = (e) => {
        e.preventDefault();
        var data, filename, link;
        var csv = this.convertArrayOfObjectsToCSV(this.props.quoteLog);
        if (csv == null)
          return;
    
        filename = 'QuoteLog' + this.state.dateFrom + '-' + this.state.dateTo + '.csv';
    
        var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
    
        if (navigator.msSaveBlob)
        { // IE 10+
          navigator.msSaveBlob(blob, filename)
        }
        else
        {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {
            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
    
        //console.log(arrOrders);
      }
    
      convertArrayOfObjectsToCSV(args) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    
        data = args || null;
        if (data == null || !data.length) {
            return null;
        }
    
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
    
        keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                //if(key != 'id' && key != 'agencyid' && key != 'createdDts' && key != 'typeQuoteLogLeadSourceId'){
                    if (ctr > 0) result += columnDelimiter;
                    if(key == 'notes'){
                        var val = item[key].toString();
                        val = val.replace(/[\n\r]+/g, '');
                        val = val.replaceAll(',', ' ');
                        result += val;
                    }
                    else{
                        result += item[key]
                    }
                    ctr++;
                //}
            });
            result += lineDelimiter;
        });
    
        return result;
    };
    handlePanelChange = (open) => {
        if(open){
            this.setState({dateFrom: null, dateTo: null, dateFilter: ''})
        }
    }
    handleReportRadioChange(e) {
        this.setState({reportFilter: e.target.value})
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo; 
        
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, e.target.value, 1, 50);
    }
    handleColumnChange(value){
        this.setState({selectedColumns: value});
    }
    handleNextPage () {
        const nextPage = this.state.pageNumber + 1;
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const reportFilter = this.state.reportFilter;
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, reportFilter, nextPage, 50);
    }
    handlePreviousPage () {
        const nextPage = this.state.pageNumber - 1;
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const reportFilter = this.state.reportFilter;
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, reportFilter, nextPage, 50);
    }
    copyToClipboard (text) {
        navigator.clipboard.writeText(text);
    
        console.log("Copied: " + text);
    }
    render() {
        
        const filterLeadSource = [];
        this.props.leadSource.map (ls =>
            filterLeadSource.push({
                text: ls.name,
                value: ls.name
            })
        );
        const filterAgent = [];
        this.props.agent.filter(a => a.status != "5").map (ls =>
            filterAgent.push({
                text: ls.firstName,
                value: ls.firstName
            })
        );
        const filterReferringAgent = [];
        filterReferringAgent.push({
            text: "",
            value: ""
        })
        this.props.referringAgent.map (ra =>
            filterReferringAgent.push({
                text: ra.name,
                value: ra.name
            })
        );
        const filterObjection = [];
        this.props.objection.map (o =>
            filterObjection.push({
                text: o.name,
                value: o.name
            })
        );
        const filterIneligible = [];
        this.props.ineligible.map (o =>
            filterIneligible.push({
                text: o.name,
                value: o.name
            })
        );
        filterReferringAgent.sort();
        const dateFormat = 'MM/DD/YYYY';
        var showFullNote = this.state.showFullNote;
        console.log(showFullNote);
        const columns = [
            {
                title: 'Quote Date',
                dataIndex: 'dts',
                key: 'dts',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.dts : [],
                render: date => moment(date).format(dateFormat),
            },
            {
                title: 'Bind Date',
                dataIndex: 'bindDate',
                key: 'bindDate',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.binddate : [],
                render: date => date != null ? moment(date).format(dateFormat) : '',
            },
            {
                title: 'Agent',
                dataIndex: 'agent',
                key: 'agent',
                filters: filterAgent,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.agent : [],
                onFilter: (value, record) => record.agent.indexOf(value) === 0,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                filters: [
                    // {
                    //     text: 'SOLD',
                    //     value: 'SOLD',
                    // },
                    {
                        text: 'HOT',
                        value: 'HOT',
                    },
                    {
                        text: 'WARM',
                        value: 'WARM',
                    },
                    {
                        text: 'COLD',
                        value: 'COLD',
                    },
                    {
                        text: 'TERMINATED',
                        value: 'TERMINATED',
                    },
                  ],
                  filterMultiple: true,
                  filteredValue: this.state.filteredInfo ? this.state.filteredInfo.status : [],
                  //defaultFilteredValue: this.state.tableSearch.filter(t => t.searchIndex == 'status').length > 0 ? [this.state.tableSearch.filter(t => t.searchIndex == 'status')[0].searchText] : [],
                  onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.firstName : [],
                //defaultFilteredValue: this.state.tableSearch.filter(t => t.searchIndex == 'firstName').length > 0 ? [this.state.tableSearch.filter(t => t.searchIndex == 'firstName')[0].searchText] : [],
                ...this.getColumnSearchProps('firstName'),
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.lastName : [],
                ...this.getColumnSearchProps('lastName'),
            },
            {
                title: 'Lead Source',
                dataIndex: 'leadSource',
                key: 'leadSource',
                render: (text, record) => this.props.leadSource.filter(l => l.id == text).length == 0 ? text : this.props.leadSource.find(l => l.id == text).name,
                //...this.getColumnSearchProps('leadSource'),
                filters: filterLeadSource,
                filterMultiple: true,
                filterSearch: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.leadSource : [],
                onFilter: (value, record) => record.leadSource.indexOf(value) === 0,
            },
            {
                title: 'Dwelling',
                dataIndex: 'dwelling',
                key: 'dwelling',
                width: '10%',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.dwelling : [],
                ...this.getColumnSearchProps('dwelling'),
            },
            {
                title: 'Auto',
                dataIndex: 'auto',
                key: 'auto',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.auto : [],
                ...this.getColumnSearchProps('auto'),
            },
            {
                title: 'Comm. Auto',
                dataIndex: 'commercialAuto',
                key: 'commercialAuto',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.commercialAuto : [],
                ...this.getColumnSearchProps('commercialAuto'),
            },
            {
                title: 'Specialty',
                dataIndex: 'specialty',
                key: 'specialty',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.specialty : [],
                ...this.getColumnSearchProps('specialty'),
            },
            {
                title: 'Objection',
                dataIndex: 'objection',
                key: 'objection',
                filters: filterObjection,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.objection : [],
                onFilter: (value, record) => record.objection.indexOf(value) === 0,
                // filteredValue: this.state.filteredInfo ? this.state.filteredInfo.objection : [],
                // ...this.getColumnSearchProps('objection'),
            },
            {
                title: 'Ineligible',
                dataIndex: 'ineligible',
                key: 'ineligible',
                filters: filterIneligible,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.ineligible : [],
                onFilter: (value, record) => record.ineligible.indexOf(value) === 0,
                // filteredValue: this.state.filteredInfo ? this.state.filteredInfo.ineligible : [],
                // ...this.getColumnSearchProps('ineligible'),
            },
            {
                title: 'Zip',
                dataIndex: 'zip',
                key: 'zip',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.zip : [],
                ...this.getColumnSearchProps('zip'),
            },
            {
                title: 'Notes',
                dataIndex: 'notes',
                key: 'notes',
                width: 500,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.notes : [],
                render: (text, record) => {
                return(
                    text.length > 80 && showFullNote != record.id ?
                    <div>
                        <Text>{text.substring(0, 80) + "..."}</Text>
                        <Button type="link" onClick={() => this.setState({showFullNote: record.id})}>read more</Button>
                    </div>
                    :
                    <div>
                        <Text>{text}</Text>
                        <br />
                        <Tooltip title="copy to clipboard" >
                            <Button type="primary" size="small" shape="circle" icon={<CopyOutlined />} style={{display: text.length == 0 ? 'none' : 'block'}} onClick={() => this.copyToClipboard(text)} />
                        </Tooltip>
                        {text.length > 80 &&
                        <Button type="link" onClick={() => this.setState({showFullNote: ''})}>read less</Button>
                        }
                    </div>
                )
                // <div>{text.length > 80 && showFullNote == false && text.substring(0, 80) + "..."} {text.length > 80 && <Button type="link" onClick={() => this.setState({showFullNote: true})}>read more</Button>}  {text.length <= 80 && text}</div>
                },
                ...this.getColumnSearchProps('notes'),
            },
            {
                title: 'Bundle',
                dataIndex: 'bundle',
                key: 'bundle',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.bundle : [],
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.bundle === value
            },
            {
                title: 'Self Gen',
                dataIndex: 'selfGenerated',
                key: 'selfGenerated',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.selfGenerated : [],
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.selfGenerated === value
            },
            {
                title: 'Dwelling Ins Score',
                dataIndex: 'insuranceScore',
                key: 'insuranceScore',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.insuranceScore : [],
                ...this.getColumnSearchProps('insuranceScore'),
            },
            {
                title: 'Auto Ins Score',
                dataIndex: 'insuranceScoreAuto',
                key: 'insuranceScoreAuto',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.insuranceScore : [],
                ...this.getColumnSearchProps('insuranceScoreAuto'),
            },
            {
                title: 'Premium',
                dataIndex: 'totalPremium',
                key: 'totalPremium',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.premium : [],
                render: (text, record) => new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(text),
                ...this.getColumnSearchProps('totalPremium'),
            },
            {
                title: 'Referring Agent',
                dataIndex: 'referringAgentName',
                key: 'referringAgentName',
                filters: filterReferringAgent,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.referringAgentName : [],
                //render: (text, record) => this.props.referringAgent && text != 0 ? this.props.referringAgent.find(ra => ra.id == text).name : "",
                onFilter: (value, record) => record.referringAgentName == value,
                
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => <a onClick={() => this.handleTableClick(record)}>Edit</a>,
            },
        ];
        
        const filteredColumns = this.state.selectedColumns.length == 0 ? columns : columns.filter(c => this.state.selectedColumns.includes(c.title));
        const userId = localStorage.getItem('userId');
        const loggedInAgent = this.props.agent.filter(a => a.userId == userId);
        const loggedInAgentName = loggedInAgent.length == 0 ? '' : loggedInAgent[0].firstName;
        const loggedInAgentId = loggedInAgent.length == 0 ? '' : loggedInAgent[0].agentId;
        const onlySelf = loggedInAgent.length == 0 ? false : loggedInAgent[0].onlySelf
        console.log(loggedInAgentName + " - " + onlySelf);
        const handleChange = (pagination, filters, sorter) => {
            console.log('Various parameters', pagination, filters, sorter);
            this.setState({filteredInfo: filters});
            //setFilteredInfo(filters);
            //setSortedInfo(sorter);
        };
        const clearFilters = () => {
            console.log('clear filters');
            this.setState({filteredInfo: [], dateFilter: '', reportFilter: 'All', dateFrom: moment().startOf('month').format("MM/DD/YYYY"), dateTo: moment().endOf('month').format("MM/DD/YYYY")});
            const agencyId = localStorage.getItem('agencyId');
            this.props.getQuoteLogReport(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), 'All');
          };
        return (
            <div>
            {/* {
            this.state.showConfetti &&
            <ConfettiExplosion
                force= {0.8}
                duration= {5000}
                particleCount= {250}
                width= {2600} />
            } */}
            <Row style={{paddingBottom: 5}}>
                <Col span={24}>
                    <Card>
                        {/* <PremiumAverage /> */}
                        <Row style={{paddingBottom: 10}}>
                            <Col span={24}>
                                <ActivitySummary dateTo={this.state.dateTo} dateFrom={this.state.dateFrom} refresh={this.state.activitySummaryRefresh} loggedInAgentName={loggedInAgentName} onlySelf={onlySelf} loggedInAgentId={loggedInAgentId}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <QuoteLogEntry hideButton={false} visible={this.state.visible} selectedQuoteLog={this.state.selectedQuoteLog} onVisibleChange={this.handleEntryVisibleChange} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} type={this.state.reportFilter} />
                </Col>
            </Row>
            <Row style={{paddingTop: 20}}>
                <Col span={24}>
                    <Title level={4}>AGENT ACTIVITY LOG</Title>
                </Col>
            </Row>
            {
            !this.props.isQuoteLogLoading &&
            <div>
            <Row>

                <Col span={3} style={{display: 'none'}}>
                    <Button type="round" onClick={this.handleOpenSearch} style={{backgroundColor: '#97E45D'}}>
                        <SearchOutlined />Sort Quote Log
                    </Button>
                </Col>
                {
                    this.state.openSearch == true &&
                        <Col span={24}>
                            <Row style={{paddingTop: 5}}>
                                <Col span={20}>
                                    <Row>
                                        <Col span={24}>
                                            <Space direction="vertical">
                                                <Radio.Group defaultValue="a" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.searchFilter}>
                                                    <Radio.Button value="date">Date Range</Radio.Button>
                                                    <Radio.Button value="name">Prospect Name</Radio.Button>
                                                    {/* <Radio.Button value="agent">Agent</Radio.Button>
                                                    <Radio.Button value="status">Status</Radio.Button>
                                                    <Radio.Button value="vendor">Vendor</Radio.Button> */}
                                                </Radio.Group>
                                                { this.state.searchFilter == 'date' ?
                                                <Space>
                                                    <Radio.Group defaultValue="thisMonth" buttonStyle="solid" onChange={this.handleDateRadioChange} value={this.state.dateFilter}>
                                                        <Radio.Button value="today">Today</Radio.Button>
                                                        <Radio.Button value="thisWeek">This Week</Radio.Button>
                                                        <Radio.Button value="lastWeek">Last Week</Radio.Button>
                                                        <Radio.Button value="thisMonth">This Month</Radio.Button>
                                                        <Radio.Button value="lastMonth">Last Month</Radio.Button>
                                                        <Radio.Button value="all">All Time</Radio.Button>
                                                    </Radio.Group>
                                                    <RangePicker format={'MM/DD/YYYY'} allowEmpty={true}
                                                        ranges={{
                                                            Today: [moment(), moment()],
                                                            'This Week': [moment().startOf('week'), moment().endOf('week')],
                                                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                        }}
                                                        defaultValue={[moment().startOf('week'), moment().endOf('week')]}
                                                        //onCalendarChange={this.handleDateRangeChange}
                                                        onChange={this.handleDateRangeChange}
                                                        value={this.state.dateFrom == null ? [] : [moment(this.state.dateFrom), moment(this.state.dateTo)]}
                                                        onOpenChange={this.handlePanelChange}
                                                    />
                                                </Space>
                                                :
                                                <Search placeholder="Search" onSearch={value => this.handleSearch(value)} enterButton />
                                                }
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                }
            </Row>
            <Row style={{paddingTop: 20}}>
                <Col md={12}>
                    <Space>
                        <Radio.Group defaultValue="All" buttonStyle="solid" onChange={this.handleReportRadioChange} value={this.state.reportFilter}>
                            <Radio.Button value="Quoted">Quoted</Radio.Button>
                            <Radio.Button value="Sold">Sold</Radio.Button>
                            <Radio.Button value="All">All</Radio.Button>
                        </Radio.Group>
                        <Button shape="round" onClick={() => this.setState({modalVisible: true})}>
                            <TableOutlined /> Select Columns
                        </Button>
                        <Button shape="round" onClick={clearFilters} style={{color: this.state.filtered ? 'Blue' : 'Black'}}>
                                <FunnelPlotOutlined /> Clear Filters
                        </Button>
                    </Space>
                </Col>
                <Col md={12}>
                    <Button shape="round" onClick={this.exportToCSV} style={{position: 'absolute', right: 0}}>
                        <DownloadOutlined /> Export
                    </Button>
                </Col>
            </Row>
            </div>
            }
            <Row>
                <Col span={24} style={{textAlign: "center"}}> 
                    {
                        this.props.isQuoteLogLoading ?
                        <div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading Agent Activity Log</Title></p></div>:
                        <div>
                            <Table onChange={handleChange} dataSource={onlySelf == false ? this.props.quoteLog : this.props.quoteLog.filter(a => a.agent == loggedInAgentName)} columns={filteredColumns} pagination={{ pageSize: 50 }}  />
                        </div>
                    }
                    
                </Col>
            </Row>
            {/* <Row>
                <Col span={24}>
                    <Button shape="round" onClick={this.handlePreviousPage} style={{position: 'absolute', right: 100}}>
                        Previous Page <ArrowLeftOutlined />
                    </Button>
                    <Button shape="round" onClick={this.handleNextPage} style={{position: 'absolute', right: 0}}>
                        Next Page <ArrowRightOutlined />
                    </Button>
                </Col>
            </Row> */}
            
            <Modal 
                width="25%"
                visible={this.state.modalVisible}
                title="SELECT COLUMNS"
                onOk={this.handleCloseModal}
                onCancel={this.handleCloseModal}
                footer={[
                    <Row style={{paddingBottom: 10}} >
                        <Col sm={12}>
                            <Button style={{ float: 'left' }} type="primary" shape="round" onClick={this.handleCloseModal}>
                                OK
                            </Button>
                        </Col>
                    </Row>,
                ]}>
                    <Checkbox.Group style={{ width: '100%' }} onChange={this.handleColumnChange}>
                        <Row>
                            {columns.map(c => 
                                <Col span={8} key={c.key}>
                                    <Checkbox value={c.title}>{c.title}</Checkbox>
                                </Col>
                            )}
                        </Row>
                    </Checkbox.Group>
            </Modal>
            
            
            <Confetti
                run={this.state.showConfetti}
                recycle={this.state.recycleConfetti}
            />
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(QuoteLog);