import React from 'react';
//import { Route } from 'react-router';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'; 
import logo from './logo.svg';
import './App.css';
import Layout from './components/Layout';
import CompPlan from './components/CompPlan';
import Vendors from './components/Vendors';
import Login from './components/Login';
import AgencyGoals from './components/AgencyGoals';
import SalesAgent from './components/SalesAgent';
import Marketing from './components/Marketing';
import QuoteLog from './components/QuoteLog';
import QuoteLogEntry from './components/QuoteLogEntry';
import Agency from './components/Agency';
import Reset from './components/Reset';
import Register from './components/Register';
import Reports from './components/Reports';
import Onboarding from './components/Onboarding';
import VendorActivitySummary from './components/VendorActivitySummary';
import VendorZipCodeSummary from './components/VendorZipCodeSummary';
import ZipCodeSummary from './components/ZipCodeSummary';
import Settings from './components/Settings';
import VendorType from './components/VendorType';
import ZipUpload from './components/ZipUpload';
import ZipUploadReport from './components/ZipUploadReport';
import SeriousUpload from './components/SeriousUpload';
import VendorTypeSummaryChart from './components/VendorTypeSummaryChart';
import Dashboard from './components/Dashboard';
import PolicySummary from './components/PolicySummary';
import PremiumAverage from './components/PremiumAverage';
import ActivitySummary from './components/ActivitySummary';
import AgentGoals from './components/AgentGoals';
import PremiumAverageAgent from './components/PremiumAverageAgent';
import Graphs from './components/Graphs';
import SalesAgentReport from './components/SalesAgentReport';
import Home from './components/Home';
import DNQReport from './components/DNQReport';
import SeriousUploadReport from './components/SeriousUploadReport';
import Agreement from './components/Agreement';
import Admin from './components/Admin';
import NewBusinessUpload from './components/NewBusinessUpload';
import NewBusinessUploadReport from './components/NewBusinessUploadReport';
import ReferringAgent from './components/ReferringAgent';
import Education from './components/Education';
import CurrentCarrier from './components/CurrentCarrier';
import ReferringAgentSummary from './components/ReferringAgentSummary';
import UploadEducation from './components/UploadEducation';
import AgencyEduMaterials from './components/AgencyEduMaterials';
import CostAcquisition from './components/CostAcquisition';
import TerminationUpload from './components/TerminationUpload';
import TerminationUploadReport from './components/TerminationUploadReport';

export default () => (
  <div>
    <Router>  
        <Switch>
          <Route exact path="/">  
            <Redirect to="/Home" />  
          </Route>
          <Route exact path='/Register' component={Register} />
          <Route exact path='/Reset/:email' component={Reset} />
          <Route exact path='/Login' component={Login} />
          <Route exact path='/Home' component={Home} />
          {/* <Login path="/Login" component={Login} />
          <RegisterAgent path="/RegisterAgent" component={RegisterAgent} />
          <Register path="/Register" component={Register} /> */}
          <Layout>
            <Route path='/CompPlan' component={CompPlan} />
            <Route path='/Vendors' component={Vendors} />
            <Route path='/Marketing' component={Marketing} />
            <Route path='/AgencyGoals' component={AgencyGoals} />
            <Route path='/SalesAgent' component={SalesAgent} />
            <Route path='/QuoteLog' component={QuoteLog} />
            <Route path='/Agency' component={Agency} />
            <Route path='/Reports' component={Reports} />
            <Route path='/Onboarding' component={Onboarding} />
            <Route path='/VendorActivitySummary' component={VendorActivitySummary} />
            <Route path='/VendorZipCodeSummary' component={VendorZipCodeSummary} />
            <Route path='/ZipCodeSummary' component={ZipCodeSummary} />
            <Route path='/Settings' component={Settings} />
            <Route path='/VendorType' component={VendorType} />
            <Route path='/ZipUploadReport' component={ZipUploadReport} />
            <Route path='/ZipUpload' component={ZipUpload} />
            <Route path='/Upload' component={SeriousUpload} />
            <Route path='/VendorTypeSummaryChart' component={VendorTypeSummaryChart} />
            <Route path='/SeriousUpload' component={SeriousUpload} />
            <Route path='/Dashboard' component={Dashboard} />
            <Route path='/PolicySummary' component={PolicySummary} />
            <Route path='/PremiumAverage' component={PremiumAverage} />
            <Route path='/ActivitySummary' component={ActivitySummary} />
            <Route path='/AgentGoals' component={AgentGoals} />
            <Route path='/PremiumAverageAgent' component={PremiumAverageAgent} />
            <Route path='/Graphs' component={Graphs} />
            <Route path='/SalesAgentReport' component={SalesAgentReport} />
            <Route path='/DNQReport' component={DNQReport} />
            <Route path='/QuoteLogEntry' component={QuoteLogEntry} />
            <Route path='/SeriousUploadReport' component={SeriousUploadReport} />
            <Route path='/Agreement' component={Agreement} />
            <Route path='/AgencyLst' component={Admin} />
            <Route path='/NewBusinessUpload' component={NewBusinessUpload} />
            <Route path='/NewBusinessUploadReport' component={NewBusinessUploadReport} />
            <Route path='/ReferringAgent' component={ReferringAgent} />
            <Route path='/Education' component={Education} />
            <Route path='/CurrentCarrier' component={CurrentCarrier} />
            <Route path='/ReferringAgentSummary' component={ReferringAgentSummary} />
            <Route path='/UploadEducation' component={UploadEducation} />
            <Route path='/AgencyEduMaterials' component={AgencyEduMaterials} />
            <Route path='/CostAcquisition' component={CostAcquisition} />
            <Route path='/TerminationUploadReport' component={TerminationUploadReport} />
            <Route path='/TerminationUpload' component={TerminationUpload} />
          </Layout>
        </Switch>  
      </Router>  
    </div>
  );
