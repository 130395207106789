import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, InputNumber, Typography, Select, List, Button, Input, DatePicker, Cascader, Radio } from 'antd'
import { SaveOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment'
const { Title, Text } = Typography;
const {Option} = Select;

class Telemarketing extends Component {
    constructor(props) {
      super(props);
        this.state = {
            telemarketingCallCount: 0,
            actualTelemarketingCallCount: 0,
            telemarketingTransferRate: 0,
            telemarketingContactRate: 0,
            telemarketingQuoteRatio: 0,
            telemarketingCloseRatio: 0,
            telemarketingPerHousehold: 1,
            telemarketingContactRateCalc: 1,
            telemarketingTransferRateCalc: 1,
            telemarketingQuoteRatioCalc: 1,
            telemarketingCloseRatioCalc: 1,
            telemarketingTotalPremium: 1,
            telemarketerCount: 0,
            telemarketerMonthlyCost: 0,
            telemarketerMonthlyCostCalc: 1,
            telemarketerMonthlyCostWrittenItems: 1,
            selectedLeadSourceId: '',
            selectedValues: [],
            name: '',
            telemarketingQuotedHouseholds: '',
            telemarketingQuotedPolicies: '',
            telemarketingQuotedItems: '',
            telemarketingSoldHouseholds: '',
            telemarketingSoldPolicies: '',
            telemarketingSoldItems: '',
            monthDts: moment().format("MMM YYYY"),
            dataLeadCount: 0,
            actualDataLeadCount: 0,
            costPerDataLead: 0,
            actualCostPerDataLead: 0,
            leadSourceFilter: 'Active',
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
    componentDidMount() {
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        this.props.getTypeVendorTypeLeadSources(agencyId);
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        this.props.getTelemarketing(agencyId, moment().format("MMM YYYY"));
        this.props.getLeadSource(agencyId);
    }
    componentDidUpdate(prevProps){
        if(prevProps.telemarketing != this.props.telemarketing){
            this.props.onTelemarketingTotal();
        }
    }
    onTelemarketingCallCountChange = value => {
        this.setState({
            telemarketingCallCount: value
        });
    }
    onActualTelemarketingCallCountChange = value => {
        this.setState({
            actualTelemarketingCallCount: value
        });
    }
    onTelemarketingTransferRateChange = value => {
        this.setState({
            telemarketingTransferRate: value
        });
    }
    onActualTelemarketingTransferRateChange = value => {
        this.setState({
            actualTelemarketingTransferRate: value
        });
    }
    onTelemarketingContactRateChange = value => {
        this.setState({
            telemarketingContactRate: value
        });
    }
    onActualTelemarketingContactRateChange = value => {
        this.setState({
            actualTelemarketingContactRate: value
        });
    }
    onTelemarketingQuoteRatioChange = value => {
        this.setState({
            telemarketingQuoteRatio: value
        });
    }
    onActualTelemarketingQuoteRatioChange = value => {
        this.setState({
            actualTelemarketingQuoteRatio: value
        });
    }
    onTelemarketingCloseRatioChange = value => {
        this.setState({
            telemarketingCloseRatio: value
        });
    }
    onTelemarketerCountChange = value => {
        this.setState({
            telemarketerCount: value
        });
    }
    onTelemarketerMonthlyCostChange = value => {     
        this.setState({
            telemarketerMonthlyCost: value
        });
    }
    onActualTelemarketerMonthlyCostChange = value => {     
        this.setState({
            actualTelemarketerMonthlyCost: value
        });
    }
    onDataLeadCountChange = value => {     
        this.setState({
            dataLeadCount: value
        });
    }
    onActualDataLeadCountChange = value => {     
        this.setState({
            actualDataLeadCount: value
        });
    }
    onCostPerDataLeadChange = value => {     
        this.setState({
            costPerDataLead: value
        });
    }
    onActualCostPerDataLeadChange = value => {     
        this.setState({
            actualCostPerDataLead: value
        });
    }
    handleVendorChange = (e) => {
        var vendor = this.props.vendors.find(v => v.id == e)
        const telemarketingTransferRate = vendor.rate;
        const telemarketingCloseRatio = vendor.closeRatio;
        const telemarketingQuoteRatio = vendor.quoteRatio;
        const selectedVendorId = vendor.id;
        this.setState({
            vendor: e,
            selectedVendorId: selectedVendorId,
            telemarketingTransferRate: telemarketingTransferRate,
            telemarketingCloseRatio: telemarketingCloseRatio,
            telemarketingQuoteRatio: telemarketingQuoteRatio
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onMonthChange(date, dateString) {
        const agencyId = localStorage.getItem("agencyId");
        if(dateString != ''){
            const dateTo = moment(date).endOf('month').format("MM/DD/YYYY");
            const dateFrom = moment(date).startOf('month').format("MM/DD/YYYY");
            this.props.getVendorSummary(agencyId, dateFrom, dateTo, '');
            this.setState({
                monthDts: dateString
            });
            this.props.getTelemarketing(agencyId, moment(date).format("MMM YYYY"));
        }
        else{
            this.setState({
                monthDts: moment().format("MMM YYYY")
            });
        } 
    };

    handleListClick = (telemarketing) => {
        var leadSource = this.props.vendorSummary.filter(v => v.vendor == telemarketing.typeQuoteLogLeadSourceId);
        var leadSourceType = this.props.typeVendorTypesLeadSources.filter(c => c.label == leadSource[0].vendorType);
        var selectedValues = telemarketing.typeQuoteLogLeadSourceId;
        this.setState({
            id: telemarketing.id,
            name: telemarketing.name,
            selectedLeadSourceId: telemarketing.typeQuoteLogLeadSourceId,
            selectedValues: selectedValues,
            telemarketingCallCount: telemarketing.monthlyCallCount, 
            telemarketingTransferRate: telemarketing.transferRate, 
            telemarketingQuoteRatio: telemarketing.quoteRatio, 
            telemarketingCloseRatio: telemarketing.closeRatio, 
            telemarketerCount: telemarketing.telemarketerCount, 
            telemarketerMonthlyCost: telemarketing.telemarketerMonthlyCost,
            telemarketingContactRate: telemarketing.contactRate,
            actualTelemarketingCallCount: telemarketing.actualMonthlyCallCount, 
            actualTelemarketingTransferRate: telemarketing.actualTransferRate, 
            actualTelemarketingQuoteRatio: telemarketing.actualQuoteRatio,
            actualTelemarketerCount: telemarketing.actualTelemarketerCount, 
            actualTelemarketerMonthlyCost: telemarketing.actualTelemarketerMonthlyCost,
            actualTelemarketingContactRate: telemarketing.actualContactRate,
            monthDts: telemarketing.monthDts,
            dataLeadCount: telemarketing.dataLeadCount,
            actualDataLeadCount: telemarketing.actualDataLeadCount,
            costPerDataLead: telemarketing.costPerDataLead,
            actualCostPerDataLead: telemarketing.actualCostPerDataLead,
        });
    }
    handleSave(e) {
        e.preventDefault()
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        const typeQuoteLogLeadSourceId = this.state.selectedLeadSourceId;
        const name = this.state.name;
        const monthlyCallCount = this.state.telemarketingCallCount;
        const transferRate = this.state.telemarketingTransferRate;
        const quoteRatio = this.state.telemarketingQuoteRatio;
        const closeRatio = this.state.telemarketingCloseRatio;
        const contactRate = this.state.telemarketingContactRate;
        const premiumPerItem = this.props.premiumPerItem;
        const telemarketerCount = this.state.telemarketerCount;
        const telemarketerMonthlyCost = this.state.telemarketerMonthlyCost;
        const dataLeadCount = this.state.dataLeadCount;
        const actualDataLeadCount = this.state.actualDataLeadCount;
        const costPerDataLead = this.state.costPerDataLead;
        const actualCostPerDataLead = this.state.actualCostPerDataLead;
        const telemarketingContactRateCalc = this.state.telemarketingCallCount * (this.state.telemarketingContactRate / 100);
        const telemarketingTransferRateCalc = telemarketingContactRateCalc * (this.state.telemarketingTransferRate / 100);
        const telemarketingQuoteRatioCalc = telemarketingTransferRateCalc * (this.state.telemarketingQuoteRatio / 100);
        const telemarketingQuotedItems = this.props.itemsPerHousehold * telemarketingQuoteRatioCalc;
        const telemarketingPerHousehold = telemarketingQuotedItems / this.props.itemsPerHousehold;
        const telemarketingQuotedPolicies = telemarketingQuotedItems / this.props.itemsPerPolicy;

        const actualTelemarketingCallCount = this.state.actualTelemarketingCallCount;
        const actualTelemarketingContactRate = this.state.actualTelemarketingContactRate;
        const actualTelemarketingTransferRate = this.state.actualTelemarketingTransferRate;
        const actualTelemarketingQuoteRatio = this.state.actualTelemarketingQuoteRatio;
        const actualTelemarketingMonthlyCost = this.state.actualTelemarketerMonthlyCost;

        const telemarketingSoldItems = telemarketingQuotedItems * (this.state.telemarketingCloseRatio / 100);
        const telemarketingSoldHouseholds = telemarketingPerHousehold * (this.state.telemarketingCloseRatio / 100);
        const telemarketingSoldPolicies = telemarketingQuotedPolicies * (this.state.telemarketingCloseRatio / 100);

        const telemarketingTotalPremium = telemarketingSoldItems * this.props.premiumPerItem;
        const monthDts = this.state.monthDts;

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.vendor == this.state.selectedLeadSourceId) : [];
        const actualTelemarketingQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualTelemarketingPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualTelemarketingQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;
        const actualTelemarketingSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualTelemarketingSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualTelemarketingSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;


        var actualDataLead = this.state.actualDataLeadCount * this.state.actualCostPerDataLead;
        const actualTelemarketerMonthlyCostCalc = parseFloat(this.state.actualTelemarketerMonthlyCost) + actualDataLead;
        const actualTelemarketerMonthlyCostWrittenItems = actualTelemarketerMonthlyCostCalc / actualTelemarketingSoldItems;
        const actualTelemarketerMonthlyHousehold = actualTelemarketerMonthlyCostCalc / actualTelemarketingSoldHousehold;
        const actualTelemarketerMonthlyPolicy = actualTelemarketerMonthlyCostCalc / actualTelemarketingSoldPolicies;

        var dataLead = this.state.dataLeadCount * this.state.costPerDataLead;
        const telemarketerMonthlyCostCalc = parseFloat(this.state.telemarketerMonthlyCost) + dataLead;
        const telemarketerMonthlyCostWrittenItems = telemarketerMonthlyCostCalc / telemarketingSoldItems;
        const telemarketerMonthlyHousehold = telemarketerMonthlyCostCalc / telemarketingSoldHouseholds;
        const telemarketerMonthlyPolicy = telemarketerMonthlyCostCalc / telemarketingSoldPolicies;

        this.props.createTelemarketing(
            agencyId, 
            userId, 
            name, 
            typeQuoteLogLeadSourceId, 
            monthlyCallCount, 
            transferRate, 
            quoteRatio, 
            closeRatio,
            contactRate, 
            telemarketerCount, 
            telemarketerMonthlyCost, 
            telemarketingPerHousehold, 
            telemarketingQuotedPolicies, 
            telemarketingQuotedItems, 
            telemarketingSoldHouseholds, 
            telemarketingSoldPolicies, 
            telemarketingSoldItems, 
            monthDts, 
            telemarketingTotalPremium, 
            premiumPerItem, 
            actualTelemarketingCallCount, 
            actualTelemarketingContactRate, 
            actualTelemarketingTransferRate, 
            actualTelemarketingQuoteRatio,
            actualTelemarketingMonthlyCost,
            telemarketerMonthlyCostWrittenItems,
            telemarketerMonthlyHousehold,
            telemarketerMonthlyPolicy,
            actualTelemarketerMonthlyCostWrittenItems,
            actualTelemarketerMonthlyHousehold,
            actualTelemarketerMonthlyPolicy,
            dataLeadCount,
            actualDataLeadCount,
            costPerDataLead,
            actualCostPerDataLead,
            telemarketerMonthlyCostCalc,
            actualTelemarketerMonthlyCostCalc);

        this.setState({
            selectedLeadSourceId: 0, 
            actualTelemarketingCallCount: 0, 
            actualTelemarketingTransferRate: 0, 
            actualTelemarketingQuoteRatio: 0,
            actualTelemarketerCount: 0, 
            actualTelemarketerMonthlyCost: 0,
            actualTelemarketingContactRate: 0,
            telemarketingCallCount: 0, 
            telemarketingTransferRate: 0, 
            telemarketingQuoteRatio: 0,
            telemarketerCount: 0, 
            telemarketerMonthlyCost: 0,
            telemarketingContactRate: 0,
            selectedValues: '',
            dataLeadCount: 0,
            actualDataLeadCount: 0,
            costPerDataLead: 0,
            actualCostPerDataLead: 0,});
    };
    getVendorName(vendorId) {
        if(this.props.vendors){
            var vendor = this.props.vendors.find(v => v.id == vendorId);
            if(vendor){
                return vendor.name;
            }
        }
    };
    handleRadioChange(e) {
        this.setState({leadSourceFilter: e.target.value});
    }
    render() {
        const telemarketingContactRateCalc = this.state.telemarketingCallCount * (this.state.telemarketingContactRate / 100);
        const telemarketingTransferRateCalc = telemarketingContactRateCalc * (this.state.telemarketingTransferRate / 100);
        const telemarketingQuoteRatioCalc = telemarketingTransferRateCalc * (this.state.telemarketingQuoteRatio / 100);
        //const telemarketingContactRateCalc = telemarketingTransferRateCalc * (this.state.telemarketingQuoteRatio / 100);
        const telemarketingCloseRatioCalc = telemarketingQuoteRatioCalc * (this.state.telemarketingCloseRatio / 100);
        const telemarketingQuotedItems = this.props.itemsPerHousehold * telemarketingQuoteRatioCalc;
        const telemarketingPerHousehold = telemarketingQuotedItems / this.props.itemsPerHousehold;
        const telemarketingQuotedPolicies = telemarketingQuotedItems / this.props.itemsPerPolicy;
        
        const telemarketingSoldItems = telemarketingQuotedItems * (this.state.telemarketingCloseRatio / 100);
        const telemarketingSoldHousehold = telemarketingPerHousehold * (this.state.telemarketingCloseRatio / 100);
        const telemarketingSoldPolicies = telemarketingQuotedPolicies * (this.state.telemarketingCloseRatio / 100);

        const telemarketingTotalPremium = telemarketingSoldItems * this.props.premiumPerItem;

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.vendor == this.state.selectedLeadSourceId) : [];

        const actualTelemarketingContactRateCalc = this.state.actualTelemarketingCallCount * (this.state.actualTelemarketingContactRate / 100);
        const actualTelemarketingTransferRateCalc = telemarketingContactRateCalc * (this.state.telemarketingTransferRate / 100);
        
        const actualTelemarketingQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualTelemarketingPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualTelemarketingQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

        const actualTelemarketingSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualTelemarketingSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualTelemarketingSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;

        const actualTelemarketingQuoteRatioCalc = actualTelemarketingTransferRateCalc * (this.state.actualTelemarketingQuoteRatio / 100);
        const actualTelemarketingCloseRatioCalc = actualTelemarketingSoldHousehold > 0 ? actualTelemarketingSoldHousehold / actualTelemarketingPerHousehold : 0;

        const actualTelemarketingTotalPremium = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPremium : 0

        const actualPremiumPerItem = actualTelemarketingTotalPremium > 0 ? actualTelemarketingTotalPremium / actualTelemarketingSoldItems : 0;

        var dataLead = this.state.dataLeadCount * this.state.costPerDataLead;
        const telemarketerMonthlyCostCalc = parseFloat(this.state.telemarketerMonthlyCost) + parseFloat(dataLead);
        const telemarketerMonthlyCostWrittenItems = telemarketerMonthlyCostCalc / telemarketingSoldItems;
        const telemarketerMonthlyHousehold = telemarketerMonthlyCostCalc / telemarketingSoldHousehold;
        const telemarketerMonthlyPolicy = telemarketerMonthlyCostCalc / telemarketingSoldPolicies;

        var actualDataLead = this.state.actualDataLeadCount * this.state.actualCostPerDataLead;
        const actualTelemarketerMonthlyCostCalc = parseFloat(this.state.actualTelemarketerMonthlyCost) + parseFloat(actualDataLead);
        const actualTelemarketerMonthlyCostWrittenItems = actualTelemarketerMonthlyCostCalc / actualTelemarketingSoldItems;
        const actualTelemarketerMonthlyHousehold = actualTelemarketerMonthlyCostCalc / actualTelemarketingSoldHousehold;
        const actualTelemarketerMonthlyPolicy = actualTelemarketerMonthlyCostCalc / actualTelemarketingSoldPolicies;

        const telemarketerCostPerQuotedHousehold = telemarketerMonthlyCostCalc / telemarketingPerHousehold;
        const actualTelemarketerCostPerQuotedHousehold = actualTelemarketerMonthlyCostCalc / actualTelemarketingPerHousehold;

        const totalFocastedSpend = (this.state.dataLeadCount * this.state.costPerDataLead) + this.state.telemarketerMonthlyCost;
        const totalActualSpend = (this.state.actualDataLeadCount * this.state.actualCostPerDataLead) + this.state.actualTelemarketerMonthlyCost;
        
        const onChange = (value) => {
            // var leadSource = this.props.vendorSummary.filter(v => v.vendor == telemarketing.typeQuoteLogLeadSourceId);
            // var leadSourceType = this.props.typeVendorTypesLeadSources.filter(c => c.label == leadSource[0].vendorType);
            // var selectedValues = [leadSourceType[0].value, telemarketing.typeQuoteLogLeadSourceId];
            var telemarketingLst = this.props.telemarketing.filter(t => t.typeQuoteLogLeadSourceId == value);
            if(telemarketingLst.length > 0){
                var telemarketing = telemarketingLst[0];
                this.setState({
                    id: telemarketing.id,
                    name: telemarketing.name,
                    selectedLeadSourceId: telemarketing.typeQuoteLogLeadSourceId,
                    selectedValues: value,
                    telemarketingCallCount: telemarketing.monthlyCallCount, 
                    telemarketingTransferRate: telemarketing.transferRate, 
                    telemarketingQuoteRatio: telemarketing.quoteRatio, 
                    telemarketingCloseRatio: telemarketing.closeRatio, 
                    telemarketerCount: telemarketing.telemarketerCount, 
                    telemarketerMonthlyCost: telemarketing.telemarketerMonthlyCost,
                    telemarketingContactRate: telemarketing.contactRate,
                    actualTelemarketingCallCount: telemarketing.actualMonthlyCallCount, 
                    actualTelemarketingTransferRate: telemarketing.actualTransferRate, 
                    actualTelemarketingQuoteRatio: telemarketing.actualQuoteRatio,
                    actualTelemarketerCount: telemarketing.actualTelemarketerCount, 
                    actualTelemarketerMonthlyCost: telemarketing.actualTelemarketerMonthlyCost,
                    actualTelemarketingContactRate: telemarketing.actualContactRate,
                    monthDts: telemarketing.monthDts,
                    dataLeadCount: telemarketing.dataLeadCount,
                    actualDataLeadCount: telemarketing.actualDataLeadCount,
                    costPerDataLead: telemarketing.costPerDataLead,
                    actualCostPerDataLead: telemarketing.actualCostPerDataLead,
                });
            }
            else {
                console.log(value);
                this.setState({
                    selectedLeadSourceId: value, 
                    actualTelemarketingCallCount: 0, 
                    actualTelemarketingTransferRate: 0, 
                    actualTelemarketingQuoteRatio: 0,
                    actualTelemarketerCount: 0, 
                    actualTelemarketerMonthlyCost: 0,
                    actualTelemarketingContactRate: 0,
                    telemarketingCallCount: 0, 
                    telemarketingTransferRate: 0, 
                    telemarketingQuoteRatio: 0,
                    telemarketerCount: 0, 
                    telemarketerMonthlyCost: 0,
                    telemarketingContactRate: 0,
                    selectedValues: value,
                    dataLeadCount: 0,
                    actualDataLeadCount: 0,
                    costPerDataLead: 0,
                    actualCostPerDataLead: 0,
                });
            };
         }
        
            
        
        const styles = {
            disabledInputText: {
                width: '100%', 
                marginLeft: 10, 
                color: '#000'
            },
        };
        return(
            <div>
                <Row>

                </Row>
                <Row>
                    <Col span={10}>
                        <List header={<Title level={4}>LEAD SOURCE</Title>} itemLayout="horizontal" dataSource={this.props.telemarketing} renderItem={item => (
                            <List.Item actions={[<a key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>edit</a>]}>
                                <List.Item.Meta title={item.name} />
                            </List.Item>
                        )} /> 
                    </Col>
                    <Col span={14}>
                        <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Month
                                </div>
                            </Col>
                            <Col md={16}>
                                <DatePicker style={{ width: '100%', marginLeft: 10 }} onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")} />
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Lead Source
                                </div>
                            </Col>
                            <Col md={16}>
                                <Radio.Group style={{marginLeft: 10}} size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.leadSourceFilter}>
                                    <Radio.Button value="Active">Active</Radio.Button>
                                    <Radio.Button value="Inactive">Inactive</Radio.Button>
                                </Radio.Group>
                                <Select
                                    virtual={false}
                                    style={{ width: '100%', marginLeft: 10 }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="leadSource"
                                    value={this.state.selectedValues}
                                    onChange={onChange}>
                                    {
                                        this.state.leadSourceFilter == "Active" ?
                                        this.props.leadSource.filter(ls => ls.inactive == false).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                        :
                                        this.props.leadSource.filter(ls => ls.inactive == true).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                    }
                                </Select>
                                {/* <Cascader
                                    style={{ width: '100%', marginLeft: 10 }}
                                    options={this.props.typeVendorTypesLeadSources.filter(c => c.children.length > 0)}
                                    onChange={onChange}
                                    maxTagCount="responsive"
                                    placeholder="Select Lead Source"
                                    value={this.state.selectedValues}
                                /> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                                          
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                FORECASTED
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                ACTUALS
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Monthly Call Count
                                </div>                               
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.telemarketingCallCount} onChange={this.onTelemarketingCallCountChange} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualTelemarketingCallCount} onChange={this.onActualTelemarketingCallCountChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Contact Rate
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.telemarketingContactRate} onChange={this.onTelemarketingContactRateChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualTelemarketingContactRate} onChange={this.onActualTelemarketingContactRateChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Transfer Rate
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.telemarketingTransferRate} onChange={this.onTelemarketingTransferRateChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualTelemarketingTransferRate} onChange={this.onActualTelemarketingTransferRateChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quote Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.telemarketingQuoteRatio} onChange={this.onTelemarketingQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualTelemarketingQuoteRatio} onChange={this.onActualTelemarketingQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Close Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.telemarketingCloseRatio} onChange={this.onTelemarketingCloseRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={new Intl.NumberFormat("en-US", {
                                    style: "percent",
                                    maximumFractionDigits: 2
                                    }).format(actualTelemarketingCloseRatioCalc)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Premium Per Item
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber disabled style={styles.disabledInputText} value={this.props.premiumPerItem} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                            <Col md={8}>
                                <InputNumber disabled style={styles.disabledInputText} value={actualPremiumPerItem.toFixed(2)} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={telemarketingPerHousehold.toFixed(2)} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualTelemarketingPerHousehold} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={telemarketingQuotedPolicies.toFixed(2)} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualTelemarketingQuotedPolicies} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={telemarketingQuotedItems.toFixed(2)} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualTelemarketingQuotedItems} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={telemarketingSoldHousehold.toFixed(2)} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualTelemarketingSoldHousehold} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={telemarketingSoldPolicies.toFixed(2)} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualTelemarketingSoldPolicies} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={telemarketingSoldItems.toFixed(2)} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualTelemarketingSoldItems} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                TOTAL WRITTEN PREMIUM
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(telemarketingTotalPremium)}</Text>
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualTelemarketingTotalPremium)}</Text>
                            </Col>
                        </Row>
                        <Row md={24} style={{backgroundColor: "#F5D5CB", fontWeight: 'bold'}}>
                            TELEMARKETING ACQUISITION COST
                        </Row>
                        <Row style={{display:'none'}}>
                            <Col md={8}>
                                Telemarketer Count
                            </Col>
                            <Col md={16}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.telemarketerCount} onChange={this.onTelemarketerCountChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                Telemarketer Monthly Cost
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.telemarketerMonthlyCost} onChange={this.onTelemarketerMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualTelemarketerMonthlyCost} onChange={this.onActualTelemarketerMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                Data Lead Count
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.dataLeadCount} onChange={this.onDataLeadCountChange} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualDataLeadCount} onChange={this.onActualDataLeadCountChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                Cost Per Data Lead
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.costPerDataLead} onChange={this.onCostPerDataLeadChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualCostPerDataLead} onChange={this.onActualCostPerDataLeadChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Total Spend
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(telemarketerMonthlyCostCalc)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualTelemarketerMonthlyCostCalc)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Telemarketing Cost Per Quoted Household
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(telemarketerCostPerQuotedHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualTelemarketerCostPerQuotedHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Telemarketing Item Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(telemarketerMonthlyCostWrittenItems)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualTelemarketerMonthlyCostWrittenItems)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Telemarketing Policy Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(telemarketerMonthlyPolicy)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualTelemarketerMonthlyPolicy)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Telemarketing Household Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(telemarketerMonthlyHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualTelemarketerMonthlyHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 10}}>
                            <Col span={24} style={{padding: 16}}>
                                <Button type="primary" shape="round" onClick={this.handleSave} style={{ position: "absolute", right: 0, fontWeight: 'bold', boxShadow: '5px 5px 5px #ccc' }}>
                                    <SaveOutlined /> SAVE
                                </Button>
                            </Col>                           
                        </Row>
                    </Col>
                </Row>
 
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Telemarketing);